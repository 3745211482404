<template>
  <div class="row">
    <div class="col-12">
   <div 
  class="course-provider" 
  :style="{ height: showAllCourses ? '600px' : 'auto' }" 
  @click.prevent="() => $router.push({ path: `/university-detail/${universityDetails.university_id}` })"
>
      <!-- @click="() =>
        $router.push({
          path: `/university-detail/${universityDetails.university_id}`,
        })
        " -->
        <div class="row mb-1 align-items-center">
          <div class="col-2">
            <figure>
              <img :src="university_logo" alt="" class="w-100" />
            </figure>
          </div>

          <div class="col-10">
            <div class="mb-1 d-flex align-items-center justify-content-between flex-wrap">
              <span class="course-provider__name">{{ universityDetails.university_name }}</span>
              <div class="d-flex align-items-center">
                <b-button variant="outline-primary" @click="navigateToBrochure(universityDetails.brochure)"
                  class="d-flex align-items-center" :disabled="!universityDetails.brochure">
                  Brochure
                  <feather-icon icon="ArrowUpRightIcon" size="18" class="cursor-pointer" />
                </b-button>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ universityDetails.country_name }}, {{ universityDetails.state_name }}
              </span>
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ universityDetails.city_name }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="universityDetails.courses && universityDetails.courses.length > 0">
          <div v-for="i in (showAllCourses ? universityDetails.courses : universityDetails.courses.slice(0, 1))" :key="'course_id_' + i.course_id">
            <Course :course="i" @apply-course-for-student="$emit('apply-course-for-student', i)"
              @recommend-course="$emit('recommend-course', i)"
              @apply-course="$emit('apply-course', i)"
              @reload-list="$emit('reload-list')" />
          </div>
        </div>
        <div v-if="showToggleButton" class="text-center course-provider__bottom" :style="{ bottom: showAllCourses ? '1.3rem' : 'auto' }">
          <b-button @click.stop.prevent="toggleCourses" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" size="sm">
            {{
              showAllCourses ? "SHOW LESS COURSES" :
              `VIEW ${remainingCoursesCount} MORE COURSES`
            }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FILESURL } from "@/config";
import Course from "./Course";
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import CommonServices from "@/apiServices/CommonServices";

export default {
  name: "CourseProvider",
  components: {
    Course,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      FILESURL,
      showAllCourses: false,
      universityDetails: this.item,
    };
  },
  computed: {
    university_logo() {
      return this.item.university_logo ? FILESURL + this.item.university_logo : "@/assets/images/svg/stanford.svg";
    },
    remainingCoursesCount() {
      return this.universityDetails.course_ids ? this.universityDetails.course_ids.split(",").length - 2 : 0;
    },
    showToggleButton() {
      return this.universityDetails.course_ids && this.universityDetails.course_ids.split(",").length > 2;
    }
  },
  methods: {
    navigateToBrochure(brochure) {
      window.location.href = `${FILESURL}${brochure}`;
    },
    toggleCourses() {
      this.showAllCourses = !this.showAllCourses;
      if (this.showAllCourses) {
        this.getAllCoursesByUniversityId();
      }
    },
    async getAllCoursesByUniversityId() {
        try {
          const res = await CommonServices.getCoursesByUniversityId(this.universityDetails.university_id);
          if (res.data.status) {
            console.log(res)
            this.universityDetails.courses = res.data.data;
          }
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
    },
  },
};
</script>

<style>
.course-provider {
  overflow-y: auto;
}
</style>
